import React, { useState, useRef } from "react";
import { Box, Typography, Container, ButtonBase, Button, TextField, InputAdornment, IconButton } from "@mui/material";
import { isMobile } from "react-device-detect";
import api from "../components/api/api";

const ContractsPage = ({ user, handleAuthError }) => {
  const [loading, setLoading] = useState(true);
  const [mobile, setMobile] = useState(0);
  const [data, setData] = useState(null);
  const dataFetchedRef = useRef(null);

  const handleResize = () => {
    if (isMobile && window.screen.orientation.type.includes("portrait")) {
      setMobile(1);
    } else if (isMobile) {
      setMobile(2);
    } else if (window.innerWidth < 800) {
      setMobile(1);
    } else {
      setMobile(0);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    api.getSelfUser((res) => {
      setData(res);
      setLoading(false);
    }, handleAuthError);
  }, []);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },

    box: {
      minHeight: "120px",
      borderWidth: 4,
      borderColor: "#ffffff",
      borderStyle: "solid",
      width: "100%",
      marginTop: 20,
      borderRadius: 10,
      padding: 20,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      color: "#ffffff",
      fontWeight: 600,
      fontSize: 24,
      textAlign: "center",
      fontFamily: "Inter",
    },
    text: {
      color: "#ffffff",
      fontSize: mobile ? 16 : 20,
      textAlign: "center",
      fontFamily: "Inter",
    },

    text: {
      color: "#ffffff",
      fontSize: 14,
      textAlign: "center",
      fontFamily: "Inter",
    },
  };

  function formatNumberWithDots(number) {
    const numberString = number.toString();
    const parts = [];
    for (let i = numberString.length - 1; i >= 0; i -= 3) {
      const part = numberString.substring(Math.max(0, i - 2), i + 1);
      parts.unshift(part);
    }
    return parts.join(".");
  }

  return (
    <Container style={styles.container}>
      <Box style={styles.box}>
        <Typography style={styles.title}>{data ? "Contratos" : "Cargando Contratos"}</Typography>
        {data && (
          <>
            {data.contracts.length > 0 && (
              <>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: mobile ? "100%" : "80%",
                    marginTop: 10,
                  }}
                >
                  <Typography style={{ ...styles.text, fontWeight: 600 }}>Contrato</Typography>
                  <Typography style={{ ...styles.text, fontWeight: 600 }}>Monto</Typography>
                  <Typography style={{ ...styles.text, fontWeight: 600 }}>Vigente</Typography>
                  <Typography style={{ ...styles.text, fontWeight: 600 }}>Documentación</Typography>
                </Box>
                <div
                  style={{
                    width: mobile ? "100%" : "80%",
                    backgroundColor: "white",
                    height: "2px",
                    marginTop: 5,
                    marginBottom: 10,
                  }}
                ></div>
              </>
            )}
            {data.contracts.length == 0 && (
              <Typography style={styles.text} key={0}>
                Aún no hay contratos
              </Typography>
            )}
            {data.contracts.length > 0 &&
              data.contracts.map((contract) => (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: mobile ? "100%" : "80%",
                    marginBottom: 10,
                  }}
                  key={contract.id}
                >
                  <Typography style={styles.text}>#{contract.internalId}</Typography>
                  <Typography style={{ ...styles.text, marginLeft: 20 }}>
                    U$S {formatNumberWithDots(contract.amount)}
                  </Typography>
                  <Typography style={{ ...styles.text, marginRight: 50 }}>
                    {contract.terminated ? "NO" : "SI"}
                  </Typography>
                  <Typography
                    style={{ ...styles.text, cursor: "pointer", fontWeight: 600 }}
                    onClick={() =>
                      window.open(`https://api.taurusargentina.com.ar/contract/${contract.id}/zip?TOKEN=${data.dni}`)
                    }
                  >
                    Descargar
                  </Typography>
                </Box>
              ))}
          </>
        )}
      </Box>
    </Container>
  );
};

export default ContractsPage;
