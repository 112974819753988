import React, { useState, useRef } from "react";
import { Box, Typography, Container, ButtonBase, Button, TextField, InputAdornment, IconButton } from "@mui/material";
import { isMobile } from "react-device-detect";
import api from "../components/api/api";

const MyDataPage = ({ user, handleAuthError }) => {
  const [loading, setLoading] = useState(true);
  const [mobile, setMobile] = useState(0);
  const [data, setData] = useState(null);
  const dataFetchedRef = useRef(null);

  const handleResize = () => {
    if (isMobile && window.screen.orientation.type.includes("portrait")) {
      setMobile(1);
    } else if (isMobile) {
      setMobile(2);
    } else if (window.innerWidth < 800) {
      setMobile(1);
    } else {
      setMobile(0);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    api.getSelfUser((res) => {
      setData(res);
      setLoading(false);
    }, handleAuthError);
  }, []);

  return (
    <Container style={styles.container}>
      <Box style={styles.box}>
        <Typography style={styles.title}>Mis Datos</Typography>
        {data && (
          <Box style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ ...styles.text, fontWeight: 600, marginRight: 5 }}>Nombre:</Typography>
              <Typography style={{ ...styles.text }}>{data.name}</Typography>
            </Box>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ ...styles.text, fontWeight: 600, marginRight: 5 }}>Apellido:</Typography>
              <Typography style={{ ...styles.text }}>{data.lastName}</Typography>
            </Box>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ ...styles.text, fontWeight: 600, marginRight: 5 }}>DNI:</Typography>
              <Typography style={{ ...styles.text }}>{data.dni}</Typography>
            </Box>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ ...styles.text, fontWeight: 600, marginRight: 5 }}>Email:</Typography>
              <Typography style={{ ...styles.text }}>{data.email}</Typography>
            </Box>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ ...styles.text, fontWeight: 600, marginRight: 5 }}>Teléfono:</Typography>
              <Typography style={{ ...styles.text }}>{data.phone}</Typography>
            </Box>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ ...styles.text, fontWeight: 600, marginRight: 5 }}>Dirección:</Typography>
              <Typography style={{ ...styles.text }}>{data.address}</Typography>
            </Box>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ ...styles.text, fontWeight: 600, marginRight: 5 }}>Código Postal:</Typography>
              <Typography style={{ ...styles.text }}>{data.postalCode}</Typography>
            </Box>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ ...styles.text, fontWeight: 600, marginRight: 5 }}>Ciudad:</Typography>
              <Typography style={{ ...styles.text }}>{data.city}</Typography>
            </Box>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ ...styles.text, fontWeight: 600, marginRight: 5 }}>Provincia:</Typography>
              <Typography style={{ ...styles.text }}>{data.state}</Typography>
            </Box>
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ ...styles.text, fontWeight: 600, marginRight: 5 }}>País:</Typography>
              <Typography style={{ ...styles.text }}>{data.country}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  box: {
    minHeight: "120px",
    borderWidth: 4,
    borderColor: "#ffffff",
    borderStyle: "solid",
    width: "100%",
    marginTop: 20,
    borderRadius: 10,
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    color: "#ffffff",
    fontWeight: 600,
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Inter",
    marginBottom: 15,
  },

  text: {
    color: "#ffffff",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Inter",
  },
};

export default MyDataPage;
