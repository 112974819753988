import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Box, Toolbar, Typography, Container, Button } from "@mui/material";
import Navbar from "./components/Navbar";
import LogInPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import ContractsPage from "./pages/ContractsPage";
import MyDataPage from "./pages/MyDataPage";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";

function App() {
  const [user, setUser] = useState(null);
  const [mobile, setMobile] = useState(0);

  const handleResize = () => {
    if (isMobile && window.screen.orientation.type.includes("portrait")) {
      setMobile(1);
    } else if (window.innerWidth < 600) {
      setMobile(1);
    } else if (isMobile) {
      setMobile(2);
    } else {
      setMobile(0);
    }
  };

  const calculateNavBarHeight = () => {
    if (window.innerWidth < 600) return 56;
    return 64;
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("taurus_user"));
    if (user) {
      setUser(user);
    } else {
      setUser(null);
    }
  }, []);

  const handleLogOut = () => {
    localStorage.setItem("taurus_user", null);
    setUser(null);
  };

  const handleLogIn = (user) => {
    console.log(user);
    localStorage.setItem("taurus_user", JSON.stringify(user));
    setUser(user);
  };

  const handleAuthError = () => {
    handleLogOut();
  };

  return (
    <>
      <Router>
        <Box
          style={{
            background: "#0A0A0A",
          }}
        >
          <Navbar
            {...{
              loggedIn: user,
              logOut: handleLogOut,
            }}
          />

          <Box
            style={{
              background: "#0A0A0A",
              //background: "#ffffff",
              display: "flex",
              width: "100%",
              marginTop: calculateNavBarHeight(),
              minHeight: !mobile ? `calc(100vh - ${calculateNavBarHeight()}px)` : undefined,
            }}
          >
            <Routes>
              <Route
                path="/"
                element={
                  !user ? (
                    <LogInPage {...{ user, loginSucceed: handleLogIn }} />
                  ) : (
                    <HomePage {...{ user, handleAuthError }} />
                  )
                }
              />
              <Route
                path="/contratos"
                element={!user ? <Navigate to="/" /> : <ContractsPage {...{ user, handleAuthError }} />}
              />
              <Route
                path="/misdatos"
                element={!user ? <Navigate to="/" /> : <MyDataPage {...{ user, handleAuthError }} />}
              />
            </Routes>
          </Box>
        </Box>
      </Router>
    </>
  );
}

export default App;
