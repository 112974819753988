import React, { useState, useRef } from "react";
import { Box, Typography, Container, ButtonBase, Button, TextField, InputAdornment, IconButton } from "@mui/material";
import { isMobile } from "react-device-detect";
import api from "../components/api/api";

const HomePage = ({ user, handleAuthError }) => {
  const [loading, setLoading] = useState(true);
  const [mobile, setMobile] = useState(0);
  const [data, setData] = useState(null);
  const dataFetchedRef = useRef(null);

  const handleResize = () => {
    if (isMobile && window.screen.orientation.type.includes("portrait")) {
      setMobile(1);
    } else if (isMobile) {
      setMobile(2);
    } else if (window.innerWidth < 800) {
      setMobile(1);
    } else {
      setMobile(0);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    api.getSelfUser((res) => {
      setData(res);
      setLoading(false);
    }, handleAuthError);
  }, []);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    topBox: {
      width: "100%",
      background: "linear-gradient(to right, #e9d376, #c69e46 )",
      borderRadius: 10,
      minHeight: 100,
      marginTop: 20,
      display: "flex",
      flexDirection: mobile ? "column" : "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "15px 15px 15px 0px",
    },

    welcomeText: {
      marginLeft: mobile ? 0 : 15,
      marginBottom: mobile ? 15 : 0,
      color: "black",
      fontWeight: "bold",
      fontSize: mobile ? 24 : 24,
      fontFamily: "Inter",
    },

    capitalBox: {
      //backgroundColor: "white",
      //boxShadow: " 0px 0px 5px rgba(0, 0, 0, 0.5)",
      borderWidth: 3,
      borderColor: "rgba(0, 0, 0, 1)",
      borderStyle: "solid",
      borderRadius: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-end",
      marginLeft: "10px",
      minHeight: "100%",
      flexWrap: "wrap",
    },

    capitalText: {
      marginRight: 10,
      marginLeft: 10,
      color: "black",

      fontWeight: "bold",
      fontSize: 14,
      textAlign: "center",
      marginTop: 2.5,
      fontFamily: "Inter",
    },

    capitalAmountText: {
      marginRight: 10,
      marginLeft: 10,
      color: "black",

      fontWeight: "bold",
      fontSize: 20,
      textAlign: "center",
      fontFamily: "Inter",
    },

    nextPaymentBox: {
      minHeight: "120px",
      borderWidth: 4,
      borderColor: "#ffffff",
      borderStyle: "solid",
      width: "100%",
      marginTop: 20,
      borderRadius: 10,
      padding: 20,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    nextPaymentTitle: {
      color: "#ffffff",
      fontWeight: 600,
      fontSize: 24,
      textAlign: "center",
      fontFamily: "Inter",
    },
    nextPaymentText: {
      color: "#ffffff",
      fontSize: mobile ? 16 : 20,
      textAlign: "center",
      fontFamily: "Inter",
    },

    paymentHistoryText: {
      color: "#ffffff",
      fontSize: 14,
      textAlign: "center",
      fontFamily: "Inter",
    },
  };

  function formatNumberWithDots(number) {
    const numberString = number.toString();
    const parts = [];
    for (let i = numberString.length - 1; i >= 0; i -= 3) {
      const part = numberString.substring(Math.max(0, i - 2), i + 1);
      parts.unshift(part);
    }
    return parts.join(".");
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate() + 1;
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const months = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    const monthName = months[monthIndex];
    return `${day} de ${monthName} de ${year}`;
  }

  function formatDate2(dateString) {
    const date = new Date(dateString);
    const day = date.getDate() + 1;
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? "0" + day : day;
    const formattedMonth = monthIndex < 10 ? "0" + monthIndex : monthIndex;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  return (
    <>
      <Container style={styles.container}>
        <Box style={styles.topBox}>
          <Typography style={styles.welcomeText}>{loading ? "Cargando..." : `Bienvenido, ${data.name}`}</Typography>
          {data && (
            <Box style={{ display: "flex", flexDirection: "row", height: "100%" }}>
              <Box style={styles.capitalBox}>
                <Typography style={styles.capitalAmountText}>
                  U$S {formatNumberWithDots(data.totalInvestment)}
                </Typography>
                <Typography style={styles.capitalText}>Capital Invertido</Typography>
              </Box>
              <Box style={styles.capitalBox}>
                <Typography style={styles.capitalAmountText}>U$S {formatNumberWithDots(data.totalProfits)}</Typography>
                <Typography style={styles.capitalText}>Ganancias Totales</Typography>
              </Box>
            </Box>
          )}
        </Box>
        {data && (
          <>
            <Box style={styles.nextPaymentBox}>
              <Typography style={styles.nextPaymentTitle}>
                {data.nextPayments.length > 1 ? "Próximos Pagos" : "Próximo Pago"}
              </Typography>
              {data.nextPayments.length > 0 &&
                data.nextPayments.map((payment) => (
                  <Typography style={styles.nextPaymentText} key={payment.id}>
                    U$S {formatNumberWithDots(payment.amount)} el {formatDate(payment.dueDate)} (contrato #
                    {payment.internalContractId})
                  </Typography>
                ))}
              {data.nextPayments.length == 0 && (
                <Typography style={styles.nextPaymentText} key={0}>
                  No hay pagos próximos
                </Typography>
              )}
            </Box>
            <Box style={{ ...styles.nextPaymentBox, marginBottom: 20 }}>
              <Typography style={styles.nextPaymentTitle}>Historial de Pagos</Typography>
              {data.paymentsHistory.length > 0 && (
                <>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: mobile ? "100%" : "80%",
                      marginTop: 10,
                    }}
                  >
                    <Typography style={{ ...styles.paymentHistoryText, fontWeight: 600 }}>Cantidad</Typography>
                    <Typography style={{ ...styles.paymentHistoryText, fontWeight: 600 }}>Fecha</Typography>
                    <Typography style={{ ...styles.paymentHistoryText, fontWeight: 600 }}>Contrato</Typography>
                    <Typography style={{ ...styles.paymentHistoryText, fontWeight: 600 }}>Estado </Typography>
                  </Box>
                  <div
                    style={{
                      width: mobile ? "100%" : "80%",
                      backgroundColor: "white",
                      height: "2px",
                      marginTop: 5,
                      marginBottom: 10,
                    }}
                  ></div>
                </>
              )}
              {data.paymentsHistory.length == 0 && (
                <Typography style={styles.nextPaymentText} key={0}>
                  Aún no hay pagos realizados
                </Typography>
              )}
              {data.paymentsHistory.length > 0 &&
                data.paymentsHistory.map((payment) => (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: mobile ? "100%" : "80%",
                      marginBottom: 10,
                    }}
                    key={payment.id}
                  >
                    <Typography style={styles.paymentHistoryText}>
                      U$S {formatNumberWithDots(payment.amount)}
                    </Typography>
                    <Typography style={styles.paymentHistoryText}>{formatDate2(payment.dueDate)}</Typography>
                    <Typography style={styles.paymentHistoryText}>#{payment.internalContractId}</Typography>
                    <Typography style={styles.paymentHistoryText}>
                      {payment.completed ? "Completado" : "Pendiente"}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default HomePage;
