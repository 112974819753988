import axios from "axios";
const API_URL = "https://api.taurusargentina.com.ar";

const _fetch = async (endpoint, onSuccess, onFailure, body, method = "get", needAuth = true) => {
  const token = (needAuth && JSON.parse(localStorage.getItem("taurus_user"))?.token) || "";
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };
  const options = {
    method,
    headers,
    url: `${API_URL}${endpoint}`,
    data: body,
  };

  try {
    const response = await axios(options);
    if (response.status >= 200 && response.status < 300) {
      onSuccess(response.data);
    } else {
      onFailure(response);
    }
  } catch (error) {
    onFailure(error.response || error);
  }
};

const authUser = (email, password, onSuccess, onFailure) => {
  _fetch("/user/auth", onSuccess, onFailure, { email, password }, "post", false);
};

const getSelfUser = (onSuccess, onFailure) => {
  _fetch("/user/me", onSuccess, onFailure, null, "get", true);
};

const api = {
  authUser,
  getSelfUser,
};

export default api;
