import { AppBar, Toolbar, Typography, Container, Button, Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

function Navbar({ loggedIn, logOut }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [appBarHeight, setAppBarHeight] = useState(0);
  const [mobile, setMobile] = useState(0);
  const navigate = useNavigate();

  const handleResize = () => {
    if (isMobile && window.screen.orientation.type.includes("portrait")) {
      setMobile(1);
    } else if (isMobile) {
      setMobile(2);
    } else if (window.innerWidth < 800) {
      setMobile(1);
    } else {
      setMobile(0);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const appBarElement = document.getElementById("TopNavBar");
    if (appBarElement) {
      const height = appBarElement.clientHeight;
      setAppBarHeight(appBarElement);
    }
  }, []);

  const handleTabClick = (tagIndex) => {
    setSelectedTab(tagIndex);
    switch (tagIndex) {
      case 0:
        navigate("/");
        break;
      case 1:
        navigate("/contratos");
        break;
      case 2:
        navigate("/misdatos");
        break;
      default:
        break;
    }
  };

  const styles = {
    tab: {
      cursor: "pointer",
      marginLeft: 10,
    },
  };

  return (
    <AppBar style={{ position: "fixed", background: "#171717" }}>
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="/img/icon.png"
            alt="Logo"
            style={{ marginLeft: mobile ? "0" : "10px", cursor: "pointer", height: 40 }}
            onClick={() => (loggedIn ? handleTabClick(0) : window.location.assign("https://taurusargentina.com.ar"))}
          />
          <Box style={{ display: "flex", gap: 15, marginLeft: mobile ? 5 : 20 }}>
            <Typography
              style={styles.tab}
              onClick={() => (loggedIn ? handleTabClick(0) : window.location.assign("https://taurusargentina.com.ar"))}
              fontWeight={selectedTab == 0 ? 600 : 200}
            >
              Inicio
            </Typography>
          </Box>
        </div>
        {loggedIn && (
          <>
            <Typography style={styles.tab} onClick={() => handleTabClick(1)} fontWeight={selectedTab == 1 ? 600 : 200}>
              Contratos
            </Typography>
            <Typography style={styles.tab} onClick={() => handleTabClick(2)} fontWeight={selectedTab == 2 ? 600 : 200}>
              Mis datos
            </Typography>
            <Typography
              style={{ ...styles.tab, marginLeft: "auto" }}
              onClick={logOut}
              fontWeight={selectedTab == 3 ? 600 : 200}
            >
              Cerrar sesión
            </Typography>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
