import React, { useState } from "react";
import { Box, Typography, Container, ButtonBase, Button, TextField, InputAdornment, IconButton } from "@mui/material";
import { isMobile } from "react-device-detect";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import api from "../components/api/api";

function LogInPage({ user, loginSucceed }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [mobile, setMobile] = useState(0);
  const [loginError, setLoginError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleTabClick = (tagIndex) => {
    setSelectedTab(tagIndex);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleResize = () => {
    if (isMobile && window.screen.orientation.type.includes("portrait")) {
      setMobile(1);
    } else if (isMobile) {
      setMobile(2);
    } else if (window.innerWidth < 800) {
      setMobile(1);
    } else {
      setMobile(0);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogIn = () => {
    setIsLoading(true);
    api.authUser(
      email,
      password,
      (response) => {
        setIsLoading(false);
        loginSucceed(response);
      },
      () => {
        setIsLoading(false);
        setLoginError(true);
      }
    );
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: mobile ? "column" : "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!mobile && (
        <img src="/assets/taurus.png" alt="Logo" style={{ maxWidth: mobile ? "80%" : "50%", width: "auto" }}></img>
      )}
      {!mobile && (
        <div
          style={{
            display: "inline-block",
            width: mobile ? "80vw" : "2px",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            margin: mobile ? "50px 20px" : "20px 40px",
            height: mobile ? "2px" : "50vh",
            borderRadius: 10,
          }}
        ></div>
      )}
      <Box display="flex" flexDirection="column" width="auto">
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Typography
            onClick={() => handleTabClick(0)}
            style={{
              color: "#ffffff",
              fontWeight: 600,
              fontFamily: "Inter",
              fontSize: mobile ? "24px" : "24px",
              borderBottom: selectedTab == 0 ? `4px solid #d1ab58` : undefined,
              padding: "8px 16px",
              cursor: "unset",
              width: "100%",
              textAlign: "center",
            }}
          >
            INICIAR SESIÓN
          </Typography>
        </Container>
        <Box display="flex" flexDirection="column" mt={5}>
          {selectedTab == 0 && (
            <>
              <TextField
                variant="standard"
                placeholder="Email"
                InputProps={styles.textField}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="standard"
                placeholder="Contraseña"
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  ...styles.textField,
                  inputProps: { style: { WebkitTextSecurity: showPassword ? "none" : "disc" } },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} style={{ color: "rgba(255, 255, 255, 0.5)" }}>
                        {!showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography color="#ffffff" textAlign="center" fontFamily="Inter" fontSize={14} mb={3}>
                ¿Olvidaste tu contraseña? <span style={{ fontWeight: 600, cursor: "pointer" }}>Click acá</span>
              </Typography>
              <Button
                style={{ ...styles.button, animation: isLoading ? "pulse 1.5s infinite alternate" : undefined }}
                onClick={handleLogIn}
                disabled={isLoading}
              >
                INGRESAR
              </Button>
              {loginError && (
                <Typography color="#CB1A1A" textAlign="center" fontFamily="Inter" fontSize={14}>
                  Datos incorrectos.
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>

      {mobile > 0 && (
        <img src="/assets/taurus.png" alt="Logo" style={{ maxWidth: mobile ? "80%" : "50%", width: "auto" }}></img>
      )}
    </Container>
  );
}

const styles = {
  textField: {
    disableUnderline: true,
    style: {
      marginBottom: 20,
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      borderRadius: 10,
      fontSize: 20,
      fontFamily: "Inter",
      fontWeight: 200,
      color: "rgba(255, 255, 255, 1)",
      "&::placeholder": {
        color: "rgba(255, 255, 255, 0.7)",
        fontSize: 14,
      },
      border: "none",
      outline: "none",
      boxShadow: "none",
      padding: "10px 20px",
    },
  },

  button: {
    background: "linear-gradient(to right, #e9d376, #c69e46 )",
    borderRadius: 10,
    fontSize: 20,
    fontFamily: "Inter",
    fontWeight: 600,
    color: "black",
    height: 54,
    marginBottom: 20,
  },
};

export default LogInPage;
